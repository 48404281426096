import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://player.vimeo.com/video/412386428"
    bibleGroupSrc="https://www.docdroid.net/miBtvHl/bible-group-homework-4-26-pdf"
    bibleGroupText="Bible Group Homework"
  >
    <SEO title="The Miracle of Healing - When Pigs Fly" />
  </Layout>
)

export default SermonPost
